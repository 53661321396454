* {
    font-family: 'Lato';
}
.btnFacebook {
    background: #4267b2;
    color: white;
    border: 1px;
    text-align: center;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    flex: 1;
    padding-left: 29px;
    width: 100%;
    height: 50px;
    align-items: center;
    border-radius: 5px;
    border-color: #4267b2;
}

.btnFacebook:focus {
    outline: 0;
}

@media screen and (min-width: 1024px) {
    .publicBannerImage {
        width: 510px;
    }
}

.footerMargin {
    border: 1px solid #f4ede5;
}

@media screen and (min-width: 1024px) {
    .footerMargin {
        margin-left: 290px;
    }
}

.trackCard {
    width: 250px;
}

.trackCardImage {
    width: 250px;
    height: 159px;
}
@media screen and (min-width: 768px) {
    .trackCard {
        width: 250px;
    }
}

@media screen and (min-width: 1024px) {
    .trackCard {
        width: 250px;
    }
}

@media screen and (min-width: 1440px) {
    .trackCard {
        width: 250px;
    }
}

@media screen and (min-width: 1920px) {
    .trackCard {
        width: 275px;
    }
}

.trackCardContainer {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin-right: 1em;
    flex-direction: row;
}
@media screen and (min-width: 768px) {
    .trackCardContainer {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        gap: 100px;
        margin-right: 1em;
        flex-direction: row;
    }
}

@media screen and (min-width: 1024px) {
    .trackCardContainer {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        gap: 54px;
        margin-right: 1em;
        flex-direction: row;
    }
}

@media screen and (min-width: 1440px) {
    .trackCardContainer {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        margin-right: 1em;
        flex-direction: row;
    }
}

@media screen and (min-width: 1920px) {
    .trackCardContainer {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        gap: 32.8px;
        margin-right: 1em;
        flex-direction: row;
    }
}

@media screen and (min-width: 1280px) {
    .trackTipSection {
        width: 533px;
    }
}

@media screen and (min-width: 1920px) {
    .trackTipSection {
        width: 719px;
    }
}

.signInContainer {
    padding-left: 20px;
    padding-right: 20px;
}

.forgotContainer {
    padding-left: 20px;
    padding-right: 20px;
}

@media screen and (min-width: 1024px) {
    .signInContainer {
        padding-left: 315px;
        padding-right: 315px;
    }
}

@media screen and (min-width: 1440px) {
    .signInContainer {
        padding-left: 515px;
        padding-right: 515px;
    }
}

@media screen and (min-width: 1920px) {
    .signInContainer {
        padding-left: 715px;
        padding-right: 715px;
    }
}

@media screen and (min-width: 1024px) {
    .forgotContainer {
        padding-left: 315px;
        padding-right: 315px;
    }
}

@media screen and (min-width: 1440px) {
    .forgotContainer {
        padding-left: 515px;
        padding-right: 515px;
    }
}

@media screen and (min-width: 1920px) {
    .forgotContainer {
        padding-left: 715px;
        padding-right: 715px;
    }
}

.radio-custom {
    opacity: 0;
    position: absolute;
}

.radio-custom,
.radio-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
}

.radio-custom-label {
    position: relative;
}

.radio-custom + .radio-custom-label:before {
    content: '';
    background: #fff;
    border: 2px solid #4c2ec2;
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
}

.radio-custom + .radio-custom-label:before {
    border-radius: 50%;
}

.radio-custom:checked + .radio-custom-label:before {
    content: url(circle-solid.svg);
    font-weight: bold;
    /* color: #4C2EC2; */
}

.radio-custom:focus + .radio-custom-label {
    outline: 1px solid #4c2ec2; /* focus style */
}

/* tick progess */
.radio-success-custom {
    opacity: 0;
    position: absolute;
}

.radio-success-custom,
.radio-success-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
}

.radio-success-custom-label {
    position: relative;
}

.radio-success-custom + .radio-success-custom-label:before {
    content: '';
    background: #fff;
    border: 2px solid #4c2ec2;
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
}

.radio-success-custom + .radio-success-custom-label:before {
    border-radius: 50%;
}

.radio-success-custom + .radio-success-custom-label:before {
    content: url(check-solid.svg);
    font-weight: bold;
    /* color: #4C2EC2; */
}

.radio-success-custom:focus + .radio-success-custom-label {
    outline: 1px solid #4c2ec2; /* focus style */
}

/* locked progess */
.radio-locked-custom {
    opacity: 0;
    position: absolute;
}

.radio-locked-custom,
.radio-locked-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
}

.radio-locked-custom-label {
    position: relative;
}

.radio-locked-custom + .radio-locked-custom-label:before {
    content: '';
    background: #fff;
    border: 2px solid #4c2ec2;
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
}

.radio-locked-custom + .radio-locked-custom-label:before {
    border-radius: 50%;
}

.radio-locked-custom + .radio-locked-custom-label:before {
    content: url(gg_lock.svg);
    font-weight: bold;
    /* color: #4C2EC2; */
}

.radio-locked-custom:focus + .radio-locked-custom-label {
    outline: 1px solid #4c2ec2; /* focus style */
}

.vertical-line {
    border-left: 2px solid #4c2ec2;
    margin-left: 2.5rem;
    padding: 0;
    padding-top: 1px;
    height: 16rem;
}

.vertical-line li {
    list-style: none;
    margin: 2em 2em 1em -1.15em;
    padding: 0;
}

.vertical-line li:before {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    margin: 0 0.5em 0 -0.65em;
    border-radius: 50%;
    text-align: center;
    background: lightgrey;
    counter-increment: list;
}

.horizontal-line {
    border-top: 2px solid #4c2ec2;
    padding: 0;
}

ul.horizontal-line-content > li {
    display: inline-block;
    position: relative;
    left: 10%;
    transform: translateX(-10%);
    margin-right: 14px;
    font-size: 12px;
}

@media screen and (max-width: 350px) {
    ul.horizontal-line-content > li {
        font-size: 10px;
    }
}

.newone[type='radio']:after {
    width: 20px;
    height: 20px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #e3daf9;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.newone[type='radio']:checked:after {
    width: 20px;
    height: 20px;
    content: url(circle-solid.svg);
}

.cust-sqaure-radio-button[type='radio']:after {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #f5f3fc;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #8c8cb4;
}

.cust-sqaure-radio-button[type='radio']:checked:after {
    width: 20px;
    height: 20px;
    background-color: #4c2ec2;
    border: 2px solid #8c8cb4;
}

.successTick {
    width: 120px;
    height: 120px;
    content: url(success.svg);
    display: flex;
    justify-content: center; /* horizontal */
}

.clapping {
    width: 100px;
    height: 100px;
    content: url(clapping.svg);
    display: flex;
    justify-content: center; /* horizontal */
}

.pageNotFound_1 {
    max-width: 90vw;
    max-height: 90vh;
    content: url(block_1.svg);
}

.pageNotFound_2 {
    max-width: 90vw;
    max-height: 90vh;
    content: url(block_2.svg);
}

.slick-dots.slick-thumb li {
    width: 10px;
    height: 10px;
    margin: 25px 5px 15px 10px;
    content: url(Ellipse1.svg);
    transition: all 0.5s ease-in-out;
}

.slick-dots.slick-thumb li.slick-active {
    width: 10px;
    height: 10px;
    content: url(Ellipse2.svg);
}
.slick-prev {
    display: none;
}
.slick-next {
    display: none;
}
.learn-outcome-list {
    list-style-image: url('tick.png');
}

.mqc-radio-botton:after {
    width: 19px;
    height: 19px;
    border-radius: 15px;
    top: -2.5px;
    left: -1px;
    position: relative;
    background-color: #e3daf9;
    content: '';
    display: inline-block;
    visibility: visible;
    margin-right: 200px;
    /* border: 2px solid white; */
}

.mqc-radio-botton:checked:after {
    background-color: #4c2ec2;
    content: url(whiteTick.svg);
}

.mqc-wrong-radio-botton:after {
    width: 19px;
    height: 19px;
    border-radius: 15px;
    top: -2.5px;
    left: -1px;
    position: relative;
    background-color: #e3daf9;
    content: '';
    display: inline-block;
    visibility: visible;
    margin-right: 200px;
    /* border: 2px solid white; */
}

.mqc-wrong-radio-botton:checked:after {
    background-color: #c22e2e;
    content: url(whiteTick.svg);
}

.challengeComplete {
    margin-top: 20px;
    content: url(education.svg);
    display: flex;
}

.challengeSumbit {
    content: url(question.svg);
    display: flex;
}

.challengeStart {
    content: url(play.svg);
    display: flex;
}
/* stroke */
nav.stroke ul li a,
nav.fill ul li a {
    position: relative;
}
nav.stroke ul li a:after,
nav.fill ul li a:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: #aaa;
    height: 1px;
}
nav.stroke ul li a:hover:after {
    width: 100%;
}

nav.fill ul li a {
    transition: all 2s;
}

nav.fill ul li a:after {
    text-align: left;
    content: '.';
    margin: 0;
    opacity: 0;
}
nav.fill ul li a:hover {
    color: #fff;
    z-index: 1;
}
nav.fill ul li a:hover:after {
    z-index: -10;
    animation: fill 1s forwards;
    -webkit-animation: fill 1s forwards;
    -moz-animation: fill 1s forwards;
    opacity: 1;
}
.help-search {
    border: 1px solid #b2a4e9 !important;
}
.help-search::placeholder {
    color: rgba(76, 46, 194, 1);
    opacity: 1; /* Firefox */
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}
.help-search:-ms-input-placeholder {
    /* Microsoft Edge | Internet Explorer 10-11 */
    color: rgba(76, 46, 194, 1);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}
.tableRow {
    border: 1px solid #d6d8e7;
    border-radius: 8px;
}

.container {
    position: relative;
    width: 313px;
    height: 320px;
}

.instituteCardcontainer {
    position: relative;
    /* width: 313px;
    height: 320px; */
}

@media screen and (min-width: 1024px) {
    .container {
        position: relative;
        width: 313px;
        height: 450px;
    }
}

.nextPreviousButton {
    top: 110px;
}

@media screen and (min-width: 1024px) {
    .nextPreviousButton {
        top: 210px;
    }
}

.instituteCardImage {
    opacity: 1;
    display: block;
    max-width: 303px;
    max-height: 229px;
    height: auto;
    transition: 0.5s ease;
    backface-visibility: hidden;
}

.image {
    opacity: 1;
    display: block;
    width: 190px;
    height: 450px;
    height: auto;
    transition: 0.5s ease;
    backface-visibility: hidden;
}

@media screen and (min-width: 1024px) {
    .image {
        opacity: 1;
        display: block;
        width: 313px;
        height: 450px;
        height: auto;
        transition: 0.5s ease;
        backface-visibility: hidden;
    }
}

.youtubeImage {
    opacity: 1;
    display: block;
    width: 450px;
    height: 450px;
    transition: 0.5s ease;
    backface-visibility: hidden;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (min-width: 1024px) {
    .youtubeImage {
        opacity: 1;
        width: 450px;
        height: 450px;
        transition: 0.5s ease;
        backface-visibility: hidden;
        display: block;
        margin: 0 auto;
    }
}

.middle {
    width: 100%;
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 55%;
    left: 53%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.instituteCardMiddle {
    width: 100%;
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 43%;
    left: 79%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.container:hover .image {
    opacity: 0.3;
}

.container:hover .youtubeImage {
    opacity: 0.3;
}

.container:hover .middle {
    opacity: 1;
}

.instituteCardcontainer:hover .instituteCardImage {
    opacity: 0.3;
}

.instituteCardcontainer:hover .instituteCardMiddle {
    opacity: 1;
}

.rotate {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

.challengeHeading {
    /* word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    font-size: 12px;
    line-height: 16px; */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
}

.challengeDescription {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    font-size: 12px;
    line-height: 16px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.featureStoryDescription {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

@media screen and (min-width: 1024px) {
    .featureStoryDescription {
        -webkit-line-clamp: 7;
    }
}

.inputFieldThickness {
    border: 1px solid #d6d8e7;
    border-radius: 4px;
}

.inputFieldErrorThickness {
    border: 1px solid red;
    border-radius: 4px;
}
.mobileNumberCountryDropdown {
    position: absolute;
    top: 40px;
    border-radius: 4px;
}
.challenge-progress-line > svg {
    height: 8px !important;
    width: 100% !important;
}

/* Checkbox styling */
.styled-checkbox {
    position: absolute;
    opacity: 0;
}

.styled-checkbox + label {
    position: relative;
    cursor: pointer;
    padding: 0;
}

.styled-checkbox + label:before {
    content: '';
    margin-right: 5px;
    display: inline-block;
    vertical-align: text-top;
    width: 14px;
    height: 14px;
    background: white;
    border-radius: 3px;
    border: 1.5px solid #8c8cb4;
}

.styled-checkbox:hover + label:before {
    background: #7666f1;
    border-radius: 3px;
}

.styled-checkbox:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.styled-checkbox:checked + label:before {
    background: #7666f1;
    border-radius: 3px;
    margin-top: -2px;
}

.styled-checkbox:checked + label:after {
    content: '';
    position: absolute;
    left: 2px;
    top: 4px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
        4px -8px 0 white;
    transform: rotate(45deg);
}

@media screen and (min-width: 1024px) {
    .styled-checkbox + label:before {
        margin-right: 10px;
        width: 20px;
        height: 20px;
    }

    .styled-checkbox:checked + label:after {
        left: 5px;
        top: 9px;
    }
}

.DashboardSidebar::-webkit-scrollbar {
    display: none;
}

/*remove spinner from number inputs*/
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}

input:disabled {
    background-color: #f3f4f6;
}
.mobile-sidebar {
    margin-top: 70px;
}
.expand-sidebar {
    width: 100vw !important;
}

/* width */
.horizontal-scrollable-leaderboard::-webkit-scrollbar {
    height: 20px;
}

.horizontal-scrollable-leaderboard {
    scrollbar-color: #b2a4e9 #f5f3fc;
    scrollbar-width: thin;
}

/* Track */
.horizontal-scrollable-leaderboard::-webkit-scrollbar-track {
    background: #f5f3fc;
    border: 4px solid transparent;
    border-radius: 10px;
    background-clip: padding-box;
}

/* Handle */
.horizontal-scrollable-leaderboard::-webkit-scrollbar-thumb {
    background-color: #b2a4e9;
    border: 4px solid transparent;
    border-radius: 10px;
    background-clip: padding-box;
}

.onboard-phone-input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 10px;
}
.onboard-phone-input::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 10px;
}
.onboard-phone-input:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 10px;
}
.onboard-phone-input:-moz-placeholder {
    /* Firefox 18- */
    font-size: 10px;
}

#table-wrapper {
    position: relative;
}
#table-scroll {
    height: 460px;
    overflow: auto;
    margin-top: 20px;
}
#table-wrapper table {
    width: 100%;
}
#table-wrapper table thead th .text {
    position: absolute;
    top: -20px;
    z-index: 2;
    height: 20px;
    width: 35%;
    border: 1px solid red;
}

.exclamation {
    content: url(exclamation.svg);
    display: flex;
}

.modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    background: #252424cc;
    height: 100%;
    width: 100vw;
}
.modal {
    position: relative;
    width: 70%;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    margin: 20px auto;
}

.assessmentSection:hover {
    box-shadow: 1px 2px 9px #bed5f7;
}
